import {
  CART_CHECKOUT_BUTTON_CLICK,
  CART_FREE_GIFT_PROMOTION,
} from 'shared/experiments/consts'
import {
  activateExperiment,
  getExperimentOnClient,
} from 'shared/experiments/utils/experiments'
import { getExperimentNamePerTenant } from 'shared/experiments/utils/tenantBasedExperimentsUtils'
import trackEvent from 'shared/experiments/utils/trackOptimizelyEvent'
import store from 'shared/store'

export default () => {
  const { publicRuntimeConfig } = store.getState()
  const { tenant, deviceClass } = publicRuntimeConfig?.publicConfig || {}
  const cartFreeGiftPromotionExperiment = getExperimentNamePerTenant(
    CART_FREE_GIFT_PROMOTION,
    tenant,
    deviceClass
  )
  const cartFreeGiftPromotionExperimentActivated =
    window.sessionStorage.getItem(cartFreeGiftPromotionExperiment)

  const { isEnabled } = getExperimentOnClient(cartFreeGiftPromotionExperiment)

  if (!isEnabled) {
    return
  }

  const freebieContainer = document.querySelector('.o-ProductListCart__freebie')

  if (!cartFreeGiftPromotionExperimentActivated && freebieContainer) {
    void activateExperiment(cartFreeGiftPromotionExperiment)
    window.sessionStorage.setItem(cartFreeGiftPromotionExperiment, 'true')
  }

  const toCheckoutButtonBottom = document.getElementById('ToCheckout')
  const toCheckoutButtonTop = document.getElementById('ToCheckoutTop')

  toCheckoutButtonBottom?.addEventListener('click', () => {
    trackEvent(CART_CHECKOUT_BUTTON_CLICK)
  })

  toCheckoutButtonTop?.addEventListener('click', () => {
    trackEvent(CART_CHECKOUT_BUTTON_CLICK)
  })
}
